<template>
	<div>
		<s-header :name="title"></s-header>
		<van-search :clearable="false" shape="round" v-model="searchText" @update:model-value="onSearch" placeholder="搜索你需要的商品热门关键词" />
		<div class="product_list">
			<van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="loadData" @offset="10">
				<good-item :list="list"></good-item>
			</van-list>
		</div>
	</div>
</template>

<script>
	import {
		toRefs,
		reactive,
		onMounted
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import sHeader from '@/components/SimpleHeader'
	import goodItem from '@/components/GoodItem'
	import {
		tg_goodList
	} from '@/service/good'
	export default {
		components: {
			sHeader,
			goodItem
		},
		setup() {
			const route = useRoute()
			const router = useRouter()
			const state = reactive({
				title: '',
				searchText: '',
				cid: '',
				goodsTypeId: '',
				loading: false,
				finished: false,
				list: [],
				page: 1,
				totalSize: 0
			})
			onMounted(async () => {
				let {
					name,
					cid,
					goodsTypeId
				} = route.query;
				state.title = name || '商品列表';
				state.cid = cid;
				state.goodsTypeId = goodsTypeId;
			})
			const loadData = async () =>{
				state.loading = true;
				const res = await tg_goodList({
					goodsTypeId: state.goodsTypeId,
					cid: state.cid,
					goodsName: state.goodsName,
					limit: 10,
					page: state.page
				})
				if(res.errno == 0 && res.data.list.length) {
					state.totalSize = res.data.total;
					if(state.list.length >= state.totalSize){
						return state.finished = true;
					}else{
						state.page = state.page + 1;
						state.list = state.list.concat(res.data.list)
					};
					state.loading = false;
				} else {
					state.finished = true;
					state.loading = false;
				}
			}
			const onSearch = (value) => {
				state.page = 1;
				state.list = [];
				state.goodsName = value;
				loadData();
			}
			return {
				...toRefs(state),
				onSearch,
				loadData
			}
		}
	}
</script>

<style scoped lang="less">
	.product_list {
		width: 100%;
		height: 100vh;
		background-color: #f5f5f5;
		box-sizing: border-box;
		padding: 16px;
	}
</style>
