<template>
	<div class="good-item" v-for="item in list" :key="item.goodsId" @click="goToDetail(item)">
		<img :src="item.picUrl" class="img" alt="">
		<div class="good-desc">
			<div class="title">
				<span :class="['flag', item.goodsTypeId == 1 ? 'flag1' : 'flag2']">{{ item.goodsType }}</span>
				{{ item.name }}
			</div>
			<div class="price">
				<div>
					<span class="text1">¥</span>
					<span class="text2">{{ item.showPrice }}</span>
				</div>
				<div class="add_wrap" v-if="cate">
					<img src="../assets/order11.png" class="add_icon" alt="">
					<span class="text3" v-if="item.number > 0">{{ item.number }}</span>
				</div>
			</div>
			<div class="saleInfo" v-if="!cate">
				<span class="line-through">¥{{ item.counterPrice }}</span>
				<span v-if="item.goodsTypeId == 1" class="sold">已售：{{ item.salesNumber }}件</span>
				<div v-else class="shareBtn" @click.stop="onShare(item)">分享</div>
			</div>
		</div>
	</div>
	<poster :info="posterData" @poster-change="posterChange" ref="poster"></poster>
</template>
<script>
import Poster from './poster.vue'
import {
	reactive,
	toRefs,
	ref,

} from 'vue'
import {
	useRouter
} from 'vue-router'
export default {
	props: {
		list: {
			type: Array,
			default: []
		},
		cate: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Poster
	},
	setup(props) {
		const poster = ref(null)
		const router = useRouter()
		const state = reactive({
			posterData: {}
		})
		const goToDetail = (item) => {
			router.push({
				path: `/product/${item.id}`
			})
		}
		const onShare = (data) => {
			state.posterData = data
		}
		const posterChange = (data) => {
			if (data.empty) {
				state.posterData = {}
			}
		}
		return {
			...toRefs(state),
			goToDetail,
			onShare,
			posterChange,
		}
	},

}
</script>

<style lang="less" scoped>
@import '../common/style/mixin';

.good-item {
	box-sizing: border-box;
	width: 100%;
	border-bottom: 1px solid #e9e9e9;
	background: white;
	display: flex;
	margin-bottom: 10px;
	position: relative;

	img {
		width: 105px;
		height: 105px;
	}

	.flag {
		padding: 2px 4px;
		border-radius: 4px;
		font-size: 12px;
		margin-right: 10px;
	}

	.flag1 {
		background-color: rgba(255, 0, 0, .2);
		color: rgba(255, 0, 0, 1);
	}

	.flag2 {
		background-color: rgba(255, 111, 0, .2);
		color: rgba(255, 111, 0, 1);
	}

	.good-desc {
		width: calc(100% - 110px);
		flex: 1;
		font-size: 14px;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.title {
			color: #222333;
			font-size: 14px;
			width: 100%;
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.price {
			color: @primary;
			margin-top: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.text1 {
				font-size: 14px;
			}

			.text2 {
				font-size: 29px;
			}
		}
	}


	.saleInfo {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.line-through {
			text-decoration: line-through;
			color: #999;
		}

		.sold {
			font-size: 12px;
			color: #999;
		}
	}
}

.shareBtn {
	width: 86px;
	line-height: 40px;
	background: linear-gradient(180deg, #e9192e, #fc5d3e);
	border-radius: 20px;
	font-size: 16px;
	font-family: PingFangSC-Regular;
	text-align: center;
	color: #ffffff;
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.add_wrap {
	position: relative;
	.add_icon {
		width: 28px;
		height: 28px;
	}
	span{
		display: inline-block;
		min-width: 13px;
		height: 13px;
		line-height: 13px;
		text-align: center;
		background-color: #FF3B30;
		color: #fff;
		font-size: 10px;
		border-radius: 50%;
		position: absolute;
		right: -5px;
		top: -5px;
	}
}
</style>
